@import url(https://fonts.googleapis.com/css?family=Droid+Sans+Mono);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700,800|Pacifico|Poppins:400,700,900|Roboto:400,700,900);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flipClock {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 560px; }
  @media (max-width: 970px) {
    .flipClock {
      width: 320px; } }
  @media (max-width: 570px) {
    .flipClock {
      width: 200px; } }

.flipUnitContainer {
  display: block;
  position: relative;
  width: 160px;
  height: 140px;
  -webkit-perspective-origin: 50% 50%;
          perspective-origin: 50% 50%;
  -webkit-perspective: 300px;
          perspective: 300px;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0px 10px 10px -10px grey; }
  @media (max-width: 970px) {
    .flipUnitContainer {
      width: 80px !important; } }
  @media (max-width: 970px) {
    .flipUnitContainer {
      height: 70px !important; } }
  @media (max-width: 570px) {
    .flipUnitContainer {
      width: 60px !important; } }
  @media (max-width: 570px) {
    .flipUnitContainer {
      height: 50px !important; } }

.upperCard, .lowerCard {
  display: -webkit-flex;
  display: flex;
  position: relative;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  height: 50%;
  overflow: hidden;
  border: 1px solid whitesmoke; }
  .upperCard span, .lowerCard span {
    font-size: 4rem;
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    color: #333333; }
    @media (max-width: 970px) {
      .upperCard span, .lowerCard span {
        font-size: 3rem; } }
    @media (max-width: 570px) {
      .upperCard span, .lowerCard span {
        font-size: 2rem; } }

.upperCard {
  -webkit-align-items: flex-end;
          align-items: flex-end;
  border-bottom: 0.5px solid whitesmoke;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px; }
  .upperCard span {
    -webkit-transform: translateY(50%);
            transform: translateY(50%); }

.lowerCard {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  border-top: 0.5px solid whitesmoke;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px; }
  .lowerCard span {
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }

.flipCard {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }
  .flipCard span {
    font-family: "Poppins", sans-serif;
    font-size: 4rem;
    font-weight: 900;
    color: #333333; }
    @media (max-width: 970px) {
      .flipCard span {
        font-size: 3rem; } }
    @media (max-width: 570px) {
      .flipCard span {
        font-size: 2rem; } }
  .flipCard.unfold {
    top: 50%;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg);
    background-color: white;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border: 0.5px solid whitesmoke;
    border-top: 0.5px solid whitesmoke; }
    .flipCard.unfold span {
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }
  .flipCard.fold {
    top: 0%;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    background-color: white;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border: 0.5px solid whitesmoke;
    border-bottom: 0.5px solid whitesmoke; }
    .flipCard.fold span {
      -webkit-transform: translateY(50%);
              transform: translateY(50%); }

.fold {
  -webkit-animation: fold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
          animation: fold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d; }

.unfold {
  -webkit-animation: unfold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
          animation: unfold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d; }

@-webkit-keyframes fold {
  0% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  100% {
    -webkit-transform: rotateX(-180deg);
            transform: rotateX(-180deg); } }

@keyframes fold {
  0% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  100% {
    -webkit-transform: rotateX(-180deg);
            transform: rotateX(-180deg); } }

@-webkit-keyframes unfold {
  0% {
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg); }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes unfold {
  0% {
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg); }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

