
@import url('https://fonts.googleapis.com/css?family=Droid+Sans+Mono');

//$easeIn: cubic-bezier(0.25, 0.46, 0.45, 0.94)
//$easeOut: cubic-bezier(0.55, 0.085, 0.68, 0.53)
$easeInOut: cubic-bezier(0.455, 0.03, 0.515, 0.955);

$turnDuration: 0.6s;

// Animation mixin
@mixin animate($name, $easing, $duration: 300ms, $delay: 0s, $iteration: 1, $direction: normal, $fill-mode: forwards) {
	animation: $name $duration $easing $delay $iteration $direction $fill-mode; }


$lg-break: 970px;
$sm-break: 570px;
$border: whitesmoke;
$card: white;
$font-family: 'Poppins', sans-serif;
$font-size: 4rem;
$font-width: 900;
$font-color: lighten(black, 20%);
$break-medium: 900px;

.flipClock {
	display: flex;
	justify-content: space-between;
	width: 3 * 160px + 80px;
	@media (max-width: $lg-break) {
		width: 3 * 80px + 80px; }
	@media (max-width: $sm-break) {
		width: 3 * 50px + 50px; } }




.flipUnitContainer {
	display: block;
	position: relative;
	width: 160px;
	height: 140px;
	perspective-origin: 50% 50%;
	perspective: 300px;
	background-color: $card;
	border-radius: 3px;
	box-shadow: 0px 10px 10px -10px grey;
	@media (max-width: $lg-break) {
		width: 80px !important; }
	@media (max-width: $lg-break) {
		height: 70px !important; }
	@media (max-width: $sm-break) {
		width: 60px !important; }
	@media (max-width: $sm-break) {
		height: 50px !important; } }




.upperCard, .lowerCard {
	display: flex;
	position: relative;
	justify-content: center;
	width: 100%;
	height: 50%;
	overflow: hidden;
	border: 1px solid $border;

	span {
		font-size: $font-size;
		font-family: $font-family;
		font-weight: $font-width;
		color: $font-color;
		@media (max-width: $lg-break) {
			font-size: 3rem; }
		@media (max-width: $sm-break) {
			font-size: 2rem; } } }



.upperCard {
	align-items: flex-end;
	border-bottom: 0.5px solid $border;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;

	span {
		transform: translateY(50%); } }

.lowerCard {
	align-items: flex-start;
	border-top: 0.5px solid $border;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;

	span {
		transform: translateY(-50%); } }

.flipCard {
	display: flex;
	justify-content: center;
	position: absolute;
	left: 0;
	width: 100%;
	height: 50%;
	overflow: hidden;
	backface-visibility: hidden;

	span {
		font-family: $font-family;
		font-size: $font-size;
		font-weight: $font-width;
		color: $font-color;
		@media (max-width: $lg-break) {
			font-size: 3rem; }
		@media (max-width: $sm-break) {
			font-size: 2rem; } }

	&.unfold {
		top: 50%;
		align-items: flex-start;
		transform-origin: 50% 0%;
		transform: rotateX(180deg); // from 180 to 0
		background-color: $card;
		border-bottom-left-radius: 3px;
		border-bottom-right-radius: 3px;
		border: 0.5px solid $border;
		border-top: 0.5px solid $border;

		span {
			transform: translateY(-50%); } }

	&.fold {
		top: 0%;
		align-items: flex-end;
		transform-origin: 50% 100%;
		transform: rotateX(0deg); // from 0 to -180
		background-color: $card;
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;
		border: 0.5px solid $border;
		border-bottom: 0.5px solid $border;

		span {
			transform: translateY(50%); } } }

.fold {
	@include animate(fold, $easeInOut, 0.6s);
	transform-style: preserve-3d; }

.unfold {
	@include animate(unfold, $easeInOut, 0.6s);
	transform-style: preserve-3d; }

@keyframes fold {
	0% {
		transform: rotateX(0deg); }
	100% {
		transform: rotateX(-180deg); } }

@keyframes unfold {
	0% {
		transform: rotateX(180deg); }
	100% {
		transform: rotateX(0deg); } }
